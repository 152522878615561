import React from 'react';
import { Link, graphql } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import styled from 'styled-components';
import { theme, mixins, Main } from '@styles';
const { colors, fontSizes } = theme;

const TagTemplate = ({ pageContext, data, location }) => {
  const { tag } = pageContext;
  const { edges } = data.allMarkdownRemark;

  return (
    <Layout location={location}>
      <Tag>
        <StyledTagsContainer>
          <span className="breadcrumb">
            <span className="arrow">&larr;</span>
            <Link to="/blog">All memories</Link>
          </span>
          <h1>
            <span>#{tag}</span>
            <span>
              <Link to="/blog/tags">View all tags</Link>
            </span>
          </h1>
          <ul className="fancy-list">
            {edges.map(({ node }) => {
              const { title, slug, date, tags } = node.frontmatter;
              return (
                <li key={slug}>
                  <h2>
                    <Link to={slug}>{title}</Link>
                  </h2>
                  <p className="subtitle">
                    <time>
                      {new Date(date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </time>
                    <span>&nbsp;&mdash;&nbsp;</span>
                    {tags &&
                      tags.length > 0 &&
                      tags.map((tag, i) => (
                        <Link key={i} to={`/blog/tags/${kebabCase(tag)}/`} className="tag">
                          #{tag}
                        </Link>
                      ))}
                  </p>
                </li>
              );
            })}
          </ul>
        </StyledTagsContainer>
      </Tag>
    </Layout>
  );
};

export default TagTemplate;

TagTemplate.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired,
      ),
    }),
  }),
  location: PropTypes.object,
};

export const pageQuery = graphql`
  query($tag: String!) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            description
            date
            slug
            tags
          }
        }
      }
    }
  }
`;

const Tag = styled.div`
  background-color:  ${props => props.theme.blogBackground};
`;

const StyledTagsContainer = styled(Main)`
  max-width: 1000px;
  a {
    ${mixins.inlineLink};
  }
  h1 {
    color: ${props => props.theme.titleColorAlt};
    ${mixins.flexBetween};
    margin-bottom: 50px;
    a {
      font-size: ${fontSizes.lg};
      font-weight: 400;
    }
  }
  ul {
    li {
      font-size: 24px;
      h2 {
        font-size: inherit;
        margin: 0;
        a {
          color: ${props => props.theme.titleColor};
        }
      }
      .subtitle {
        color: ${props => props.theme.textColor};
        font-size: ${fontSizes.sm};

        .tag {
          margin-right: 10px;
        }
      }
    }
  }
`;